@import "~@havenengineering/module-shared-library/dist/style/global";
@import "breakpoints";
@import "colors";
@import "functions";
@import "fonts";

body,
html {
  margin: 0;
  padding: 0;
}

body {
  color: $color-black--90pc;
}

.bl-haven-myaccount {
  &__account-details {
    @media (min-width: $breakpoint-md) {
      margin-bottom: px-to-rem(50);
    }
  }

  &__account-details-container {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
    }
  }

  &__account-details--desktop {
    display: none;

    @media (min-width: $breakpoint-md) {
      display: block;
    }
  }

  &__account-details--mobile {
    @media (min-width: $breakpoint-md) {
      display: none;
    }
  }

  &__account-details__accordion {
    .bl-haven-child-accordion__tabLabel {
      display: flex;
      justify-content: space-between;
      border-top: unset;
      color: $color-blue;
      font-weight: 300;
      font-size: px-to-rem(24);
      line-height: px-to-rem(32);
      text-align: left;
      border-bottom: px-to-rem(1) solid $color-white--dark;
      padding-bottom: px-to-rem(10);
    }

    .bl-haven-child-accordion__icon {
      margin-right: px-to-rem(10);
    }
  }

  &__account-details-card {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    border: px-to-rem(1) solid $color-grey--light;
    border-radius: px-to-rem(8);
    box-shadow: px-to-rem(0, 4, 8, 0) $color-black--10pc;
    padding: px-to-rem(20);
    font-size: px-to-rem(18);
    margin: px-to-rem(20, 0);

    span {
      display: flex;
      align-items: center;

      img {
        height: px-to-rem(17);
      }
    }

    a {
      font-weight: bold;
      margin-right: px-to-rem(15);
    }

    p {
      margin-bottom: 0;
    }

    @media (min-width: $breakpoint-md) {
      margin: px-to-rem(0, 30, 0, 0);
    }
  }

  &__account-details-card:last-of-type {
    margin-bottom: 0;

    @media (min-width: $breakpoint-md) {
      margin-right: 0;
    }
  }

  &__account-details-icon {
    width: px-to-rem(50);
    margin-bottom: px-to-rem(20);
  }

  &__more-info-button {
    border: 0;
    background: 0;
    display: inline-block;
    width: px-to-rem(20);
    height: px-to-rem(20);
    vertical-align: center;
    margin: px-to-rem(0, 0, 0, 12);
    padding: 0;
    cursor: pointer;

    &__icon {
      display: block;
      width: px-to-rem(20);
      height: px-to-rem(20);
    }
  }

  &-inclusive-addons {
    .bl-haven-myaccount__more-info-button {
      padding-top: px-to-rem(4);
    }
  }
}

.bl-haven-myaccount-billing-iframe {
  width: 100%;
  border: none;
  box-sizing: content-box;
  height: px-to-rem(600);
  overflow: hidden;
  margin-top: px-to-rem(40);
}

.bl-haven-myaccount-billing-iframe__hidden-footer {
  height: px-to-rem(340);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.telephone {
  font-weight: $fontWeight-bold;
}
