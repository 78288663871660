@import "../../styles/colors";
@import "../../styles/functions";

.title {
  color: $color-red;
  font-size: px-to-rem(20);
  font-weight: 400;
  line-height: px-to-rem(24);
}

.body {
  color: $color-blue;
  font-size: px-to-rem(16);
  font-weight: 300;
  line-height: px-to-rem(24);
  margin: px-to-rem(12, 0);
}

.button {
  margin: px-to-rem(12, 0);
  cursor: pointer;
}

.link {
  display: block;
}
